<template>
    <div>
        <div class="d-flex align-center">
            <v-btn :disabled="currentTables.length === 0" @click="addToStaging(currentTables)" class="justify-start">
                <v-icon left>share</v-icon>
                stage all ({{ currentTables.length }})
            </v-btn>
            <v-divider vertical class="mx-3"></v-divider>
            <v-btn v-if="selected.length > 0" @click="addToStaging(selected)" class="mr-2">
                <v-icon left>share</v-icon>
                stage selected ({{ selected.length }})
            </v-btn>
            <v-menu offset-y bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="secondary" icon dark class="caption mr-2">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list nav dense class="py-4">
                    <v-list-item>
                        <v-btn :disabled="currentTables.length === 0" @click="addToStaging(currentTables)" block text color="secondary" class="justify-start">
                            <v-icon left>share</v-icon>
                            stage all ({{ currentTables.length }})
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn :disabled="selected.length === 0" @click="addToStaging(selected)" block text color="secondary" class="justify-start">
                            <v-icon left>share</v-icon>
                            stage selected ({{ selected.length }})
                        </v-btn>
                    </v-list-item>
                    <v-divider class="my-3"></v-divider>
                    <v-list-item>
                        <delete-button
                            objectType="table"
                            :icon="false"
                            :disabled="selected.length === 0"
                            buttonName="Delete"
                            :apiURL="`/instances/${$route.params.iid}/tables/delete`"
                            protocol="POST"
                            :selected="selected"
                            :isAsyncDeletion="true"
                            :showDialog="true"
                            objectName="Tables"
                            :warningText="`Are you sure you want to delete the selected tables permanently?`"
                            @error="errorMessage($event.error)"
                            @deleting="emptySelected(!$event.value)"></delete-button>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-text-field
                prepend-inner-icon="mdi-filter"
                autofocus
                hide-details
                solo
                flat
                background-color="grey lighten-4"
                dense
                label="Filter tables..."
                v-model="search"
                clearable
                class="mr-2"></v-text-field>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="$store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)" icon v-on="on" :loading="tablesFetching">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </template>
                <span>Refresh Tables</span>
            </v-tooltip>
        </div>
        <v-data-table
            :search="search"
            item-key="tid"
            :headers="headers"
            :options="tableOptions"
            v-model="selected"
            show-select
            :items="currentTables"
            :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
            class="shepherd-table-staging-step-1">
            <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    @update:options="updateOptions"
                    :itemsPerPageOptions="itemsPerPageOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    class="no-border" />
            </template>
            <template v-slot:[`item.short_id`]="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" text class="text-none">
                            {{ item.short_id }}
                            <v-icon right class="showOnHover">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list nav dense class="py-4">
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn class="justify-start" color="secondary" text @click="setQueryParam(item.short_id)" block>
                                    <v-icon left>filter_alt</v-icon>
                                    Query table
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn class="justify-start" color="secondary" text @click="exploreTable(item.short_id, item.tid, 'columnList')" block>
                                    <v-icon left>zoom_in</v-icon>
                                    Explore columns
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn class="justify-start" color="secondary" text @click="exploreTable(item.short_id, item.tid, 'dataPreview')" block>
                                    <v-icon left>mdi-playlist-play</v-icon>
                                    Preview table
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <copy-to-clipboard :textToCopy="quotedName(item.short_id)" buttonName="Copy name to clipboard" type="listItem"></copy-to-clipboard>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:[`item.description`]="{ item }">
                <v-tooltip max-width="700" v-if="item.description" bottom>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ item.description }}</span>
                    </template>
                    <span>{{ item.description }}</span>
                </v-tooltip>
                <span v-else>No description available.</span>
            </template>
            <template v-slot:[`item.row_count`]="{ item }">
                {{ item.row_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
            </template>
            <template v-slot:[`item.bytes`]="{ item }">{{ humanFileSize(item.bytes, false) }}</template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn @click="addToStaging([item])" color="secondary" class="mr-2 showOnHover" small outlined text>
                    <v-icon left>share</v-icon>
                    stage
                </v-btn>
                <v-btn small color="primary" @click="setQueryParam(item.short_id)" e2e-snapshot-tables-query class="mr-2">
                    <v-icon left>filter_alt</v-icon>
                    Query
                </v-btn>
                <v-menu v-if="isDevelopment" :close-on-content-click="false" left offset-x>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" text color="secondary" small class="mr-3">
                            <v-icon>more_horiz</v-icon>
                        </v-btn>
                    </template>
                    <v-list nav dense class="py-4">
                        <v-list-item>
                            <v-list-item-title>
                                <rename-button
                                    :selected="[item]"
                                    objectType="table"
                                    :dispatchFunction="
                                        () => {
                                            $store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)
                                        }
                                    "></rename-button>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                <edit-description-button
                                    :selected="[item]"
                                    objectType="table"
                                    :dispatchFunction="
                                        () => {
                                            $store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)
                                        }
                                    "></edit-description-button>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider class="my-3"></v-divider>
                        <v-list-item>
                            <v-list-item-title>
                                <delete-button
                                    objectType="table"
                                    :icon="false"
                                    buttonName="Delete"
                                    :apiURL="`/instances/${$route.params.iid}/tables/delete`"
                                    protocol="POST"
                                    :selected="[item]"
                                    :isAsyncDeletion="true"
                                    :showDialog="true"
                                    objectName="Tables"
                                    :warningText="`Are you sure you want to delete the selected tables permanently?`"
                                    @error="errorMessage($event.error)"
                                    @deleting="emptySelected(!$event.value)"></delete-button>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { humanFileSize } from '@/utils'
import { enumsData } from '@/mixins/enums'
import { queryData } from '@/mixins/query'
import DeleteButton from '@/components/DeleteButton.vue'
import Shepherd from 'shepherd.js'

const EditDescriptionButton = () => import('../components/TheSnapshotTableColDescriptionEdit.vue')
const RenameButton = () => import('../components/TheSnapshotRenameButton.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'TableListIterator',
    mixins: [enumsData, queryData],
    components: { EditDescriptionButton, RenameButton, CopyToClipboard, DeleteButton },
    data() {
        return {
            search: '',
            headers: [
                { text: 'Name', align: 'left', value: 'short_id', class: 'pl-8' },
                {
                    text: 'Description',
                    value: 'description'
                },
                { text: 'Size', value: 'bytes', align: 'end' },
                { text: 'Rows', value: 'row_count', align: 'end' },
                { text: 'Actions', align: 'right', value: 'actions', width: '350px' }
            ],
            selected: [],
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            },
            stagingTour: null
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentTables', 'stagingObjects', 'selectedTableForPreview', 'tablesFetching']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapState('tourStore', ['stageTableTourStarted'])
    },
    methods: {
        emptySelected(confirmed) {
            if (confirmed) {
                this.selected = []
            }
        },
        errorMessage: function (error) {
            if (error) {
                console.log(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `There was an error while deleting tables. Please try again later.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        },
        setQueryParam(tableName) {
            this.$router.push({
                query: { tab: this.tableViewTabNames.QUERYBUILDER, table: tableName }
            })
        },
        exploreTable(tableName, tid, target) {
            if (this.selectedTableForPreview !== tableName) {
                this.$emit('newTableSelected', { table: tableName })
                this.$store.dispatch('snapshotStore/fetchColumnAndPreviewData', { tid, tableName })
            }
            if (target === 'columnList') {
                this.tabView = 1
                this.$emit('switchTab', { tab: 1 })
            } else if (target === 'dataPreview') {
                this.$emit('switchTab', { tab: 2 })
            }
        },
        quotedName: function (name) {
            return '"' + name + '"'
        },
        humanFileSize: function (bytes, si) {
            return humanFileSize(bytes, si)
        },
        addToStaging: function (data) {
            if (data.length) {
                const stagingObjectsBefore = this.stagingObjects.tables.length
                const tables = []
                let tableOrder = data.length
                data.forEach(table => {
                    const tableObject = table
                    tableObject.filePath = table.short_id
                    tableObject.fileType = this.nuvolosObjectTypes.TABLE
                    tables.push(tableObject)
                    tableOrder = tableOrder - 1
                    if (tableOrder === 0) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: this.nuvolosObjectTypes.TABLE,
                            newItems: tables,
                            updateMode: 'add'
                        })
                    }
                })
                const stagingObjectsAfter = this.stagingObjects.tables.length
                if (stagingObjectsAfter !== stagingObjectsBefore) {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Table(s) added to stage.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Selected table(s) already staged.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                }
            }
        }
    },
    watch: {
        tablesFetching(newVal, oldVal) {
            // started fetching
            if (this.stageTableTourStarted && this.stagingTour && newVal && !oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.cancel()
                })
            }

            // finished fetching
            if (this.stageTableTourStarted && this.stagingTour && !newVal && oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.start()
                })
            }
        },
        stageTableTourStarted: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.stagingTour = new Shepherd.Tour({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                scrollTo: { behavior: 'smooth' }
                            }
                        })
                        this.stagingTour.addStep({
                            title: 'Staging tables',
                            text: `Hover on the selected object and in the Actions columns select "Stage" to add it to the staging area for the next distribution.`,
                            attachTo: {
                                element: '.shepherd-table-staging-step-1',
                                on: 'top'
                            },
                            id: 'staging-tables-1',
                            buttons: [
                                {
                                    text: 'Ok',
                                    action: this.stagingTour.complete
                                }
                            ]
                        })
                        this.stagingTour.start()
                        Shepherd.on('complete', () => {
                            this.stagingTour = null
                            this.$store.commit('tourStore/stageTableTourStarted', false)
                        })
                    })
                }
            },
            immediate: true
        }
    }
}
</script>
