<template>
    <div v-if="!showDialog && !buttonInsideDialog" class="d-flex align-center">
        <v-btn
            v-if="!isInsideDialog"
            class="mr-1"
            :icon="icon"
            :disabled="disabled"
            color="error"
            @click="deleteItem()"
            :loading="deleting"
            depressed
            e2e-delete-button-confirm>
            <v-icon small class="mr-1">delete</v-icon>
            {{ buttonName }}
        </v-btn>
        <v-btn v-else-if="isInsideDialog" text :disabled="disabled" color="error" @click="deleteItem()" :loading="deleting" e2e-delete-button-confirm>
            {{ buttonName }}
        </v-btn>
        <v-btn v-if="!icon && !isInsideDialog" color="primary" outlined exact text :to="cancelRoute" :disabled="deleting">cancel</v-btn>
    </div>
    <v-btn
        v-else-if="!showDialog && buttonInsideDialog"
        color="error"
        :icon="icon"
        :disabled="disabled"
        @click="deleteItem()"
        :loading="deleting"
        text
        e2e-delete-button-confirm>
        {{ buttonName }}
    </v-btn>
    <v-dialog v-else v-model="deleteDialog" max-width="500">
        <template v-slot:activator="{ on }">
            <v-btn
                :block="block"
                :class="block ? 'justify-start flex-grow-1' : ''"
                :icon="icon"
                :disabled="disabled"
                color="secondary"
                text
                :loading="deleting"
                v-on="on">
                <v-icon left>delete</v-icon>
                {{ buttonName }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-icon class="mr-1">delete</v-icon>
                <span class="secondary--text">{{ buttonName }}</span>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <div v-html="warningText"></div>
                <ul v-if="objectType === nuvolosObjectTypes.TABLE" class="mt-3">
                    <li v-for="table in selected" :key="table.tid">{{ table.long_id }}</li>
                </ul>
                <ul v-if="objectType === nuvolosObjectTypes.COLUMN" class="mt-3">
                    <li v-for="column in selected" :key="column.cid">{{ column.long_id }}</li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mr-1" text @click="deleteDialog = false" e2e-delete-button-cancel>Cancel</v-btn>
                <v-btn text color="error" @click="deleteItem()" e2e-delete-button-confirm>Delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortArray, isMasterInstance } from '@/utils'
import { enumsData } from '@/mixins/enums'
import { analyticsMixin } from '@/mixins/analytics'

export default {
    name: 'DeleteButton',
    mixins: [enumsData, analyticsMixin],
    props: {
        buttonName: String,
        apiURL: String,
        id: Number,
        selected: {
            type: Array,
            default() {
                return []
            }
        },
        protocol: { type: String, default: 'DELETE' },
        requestBody: { type: Object, default: null },
        higherLevelId: Number,
        warningText: String,
        routeAfter: { type: Object, default: null },
        cancelRoute: { type: Object, default: null },
        icon: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        finallyConditionRouter: { type: Boolean, default: false },
        showDialog: { type: Boolean, default: false },
        fetchString: { type: String, default: '' },
        buttonInsideDialog: { type: Boolean, default: false },
        isInsideDialog: { type: Boolean, default: false },
        objectType: String,
        objectName: String,
        isAsyncDeletion: { type: Boolean, default: false },
        block: { type: Boolean, default: true }
    },
    data() {
        return {
            deleting: false,
            deleteDialog: false,
            error: false
        }
    },
    methods: {
        fetch() {
            switch (this.protocol) {
                case 'DELETE':
                    return this.$axios.delete(this.apiURL, { timeout: 300000 })
                case 'POST': {
                    let request = this.requestBody
                    if (this.objectType === this.nuvolosObjectTypes.TABLE) {
                        request = { tids: this.selected.map(table => table.tid) }
                    }
                    if (this.objectType === this.nuvolosObjectTypes.COLUMN) {
                        request = { cids: this.selected.map(column => column.cid) }
                    }
                    return this.$axios.post(this.apiURL, request)
                }
                case 'GET':
                    return this.$axios.get(this.apiURL)
                case 'PUT':
                    return this.$axios.put(this.apiURL)
            }
        },
        deleteItem: function () {
            this.$emit('error', { error: false })
            this.$emit('deleting', { value: true })
            this.deleteDialog = false
            this.deleting = true
            this.error = false
            let showSuccessMessage = true
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            const successMessage = this.isAsyncDeletion
                ? `${this.objectName} successfully submitted for deletion, you can track its progress <a href="${userTasksRoute}">here</a>`
                : `${this.objectName} successfully deleted.`
            this.fetch()
                .then(async response => {
                    if (this.objectType === this.nuvolosObjectTypes.TABLE) {
                        this.selected.forEach(table => {
                            if (this.stagedTableIds.includes(table.tid)) {
                                this.$store.dispatch('snapshotStore/updateStagingObjects', {
                                    itemType: this.nuvolosObjectTypes.TABLE,
                                    newItems: [{ tid: table.tid }],
                                    updateMode: 'remove'
                                })
                            }
                        })
                        this.$store.dispatch('showGlobalDialog', {
                            dialogTitle: 'Deleting table',
                            dialogText: 'Table is being deleted, please wait...',
                            dialogAction: ['refreshTables', 'autoclose'],
                            taskId: response.data.tkid
                        })
                        showSuccessMessage = false
                    }
                    if (this.objectType === this.nuvolosObjectTypes.COLUMN) {
                        this.$store.dispatch('showGlobalDialog', {
                            dialogTitle: 'Deleting column',
                            dialogText: 'Column is being deleted, please wait...',
                            dialogAction: ['refreshColumns', 'autoclose'],
                            taskId: response.data.tkid,
                            options: { columnId: this.selected[0]?.tid }
                        })
                        showSuccessMessage = false
                    }
                    if (this.objectType === this.nuvolosObjectTypes.FILE && response.data.tkid) {
                        this.$store.dispatch('showGlobalDialog', {
                            dialogTitle: 'Deleting files',
                            dialogText: 'Files are being deleted, please wait...',
                            dialogAction: ['refreshfiles', 'autoclose'],
                            taskId: response.data.tkid
                        })
                        showSuccessMessage = false
                    }
                    if (this.objectType === this.nuvolosObjectTypes.SNAPSHOT) {
                        this.$store.dispatch('snapshotStore/resetSnapshotState')
                    } else if (this.objectType === this.nuvolosObjectTypes.INSTANCE && this.$route.params.iid.toString() === this.id.toString()) {
                        this.$store.dispatch('snapshotStore/resetSnapshotState')
                        this.$store.dispatch('instanceStore/resetInstanceState')
                    } else if (this.objectType === this.nuvolosObjectTypes.SPACE) {
                        this.$store.dispatch('snapshotStore/resetSnapshotState')
                        this.$store.dispatch('instanceStore/resetInstanceState')
                        this.$store.dispatch('spaceStore/resetSpaceState')
                    }
                    if (showSuccessMessage) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: successMessage,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'check_circle'
                        })
                    }
                    if (this.isAsyncDeletion) {
                        this.$store.dispatch('userStore/fetchUserTasks')
                    }
                })
                .catch(() => {
                    this.error = true
                    this.$emit('error', { error: true })
                })
                .finally(() => {
                    if (
                        this.objectType === this.nuvolosObjectTypes.SNAPSHOT &&
                        !this.error &&
                        this.isDistributedInstance &&
                        this.instanceSnapshots.length === 2
                    ) {
                        this.$store.dispatch(this.fetchString, this.higherLevelId).finally(() => {
                            this.$router.push({
                                name: 'snapshot-overview',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: this.masterInstanceData.iid,
                                    snid: this.masterInstanceData.snid
                                }
                            })
                        })
                    } else if (
                        this.objectType === this.nuvolosObjectTypes.SNAPSHOT &&
                        !this.error &&
                        this.isDistributedInstance &&
                        this.instanceSnapshots.length > 2 &&
                        this.$route.params.snid.toString() === this.id.toString()
                    ) {
                        this.$store.dispatch(this.fetchString, this.higherLevelId).finally(() => {
                            this.$router.push({
                                name: 'snapshot-overview',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: this.$route.params.iid,
                                    snid: this.mostRecentDistributedSnapshot.snid
                                }
                            })
                        })
                    } else if (!this.error && this.objectType !== this.nuvolosObjectTypes.FILE && this.fetchString) {
                        this.$store.dispatch(this.fetchString, this.higherLevelId).finally(() => {
                            if (this.routeAfter) {
                                this.$router.push(this.routeAfter)
                            }
                        })
                    }
                    this.$emit('deleting', { value: false })
                    this.deleting = false
                    this.deleteDialog = false
                })
        }
    },
    computed: {
        stageAppsIds() {
            return this.stagingObjects.applications.map(app => app.aid)
        },
        stagedTableIds() {
            return this.stagingObjects.tables.map(table => table.tid)
        },
        masterInstanceData() {
            if (this.$store.state.orgStore.spacesWithPrimarySnapshots) {
                const master = this.$store.state.orgStore.spacesWithPrimarySnapshots.find(
                    space => isMasterInstance(space.instance_long_id) && space.sid.toString() === this.$route.params.sid.toString()
                )
                return master
            }
            return null
        },
        mostRecentDistributedSnapshot() {
            if (this.isDistributedInstance && this.instanceSnapshots) {
                const sortedSnapshots = sortArray(this.instanceSnapshots, 'iid', 'descending', false)
                const nonDevelopmentSnapshots = sortedSnapshots.filter(
                    snapshot => snapshot.long_id !== 'Current State' && snapshot.snid.toString() !== this.id.toString()
                )
                if (nonDevelopmentSnapshots.length) {
                    return nonDevelopmentSnapshots[0]
                }
            }
            return null
        },
        ...mapState('snapshotStore', ['stagingObjects', 'applications']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapGetters('instanceStore', ['isDistributedInstance'])
    }
}
</script>
